<template>
  <b-card no-body>
    <b-overlay :show="!tableReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <b-card-body v-show="tableReady">
        <div v-if="tableData.length">
          <table id="priceListDataTable" class="dataTable table">
            <thead>
              <tr>
                <th>{{ $t('shop.product_code_tooltip') }}</th>
                <th>{{ $t('shop.barcode_tooltip') }}</th>
                <th>{{ $t('shop.product_name_tooltip') }}</th>
                <th>{{ $t('product.brand') }}</th>
                <th>{{ $t('transaction.p_unit') }}</th>
                <th class="text-right">{{ $t('shop.list_price') }}</th>
                <th class="text-right">{{ $t('shop.try_price') }}</th>
                <th v-if="$can('manage', '/Product/Edit')" class="text-center">{{ $t('product.process') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tableData" :key="`product_${index}_${item.product_code}`">
                <td>{{ item.product_code }}</td>
                <td>{{ item.barcode }}</td>
                <td>
                  <b-link
                    :to="{
                      name: '/Shop/Product',
                      params: { id: item.product_id }
                    }"
                    :id="'product-list-data-table-' + item.product_id"
                    target="_blank"
                  >
                    {{ item.product_name }}
                    <template v-if="item.attribute_name">
                      <br />
                      <span>{{ item.attribute_name }}</span>
                    </template>
                  </b-link>
                  <b-popover :target="'product-list-data-table-' + item.product_id" placement="right" variant="primary" triggers="hover">
                    <product-tooltip :product="item" />
                  </b-popover>
                </td>
                <td>{{ item.brand_name }}</td>
                <td>{{ item.unit_code }}</td>
                <td class="text-right" :data-order="unitPriceTRY(item)">
                  <div v-if="item.currency_code === 'USD'" class="d-flex justify-content-end align-items-center">
                    <h5 class="font-weight-bolder text-nowrap text-uppercase m-0 mr-1">{{ unitPriceShow(item) }}</h5>
                    <span class="text-muted text-nowrap text-uppercase">{{ `+ %${item.vat_rate} ${$t('shop.vat')}` }}</span>
                  </div>
                </td>
                <td class="text-right" :data-order="unitPriceTRY(item)">
                  <div class="d-flex justify-content-end align-items-center">
                    <h5 class="font-weight-bolder text-nowrap text-uppercase m-0 mr-1">{{ unitPriceTRYShow(item) }}</h5>
                    <span class="text-muted text-nowrap text-uppercase">{{ `+ %${item.vat_rate} ${$t('shop.vat')}` }}</span>
                  </div>
                </td>
                <td v-if="$can('manage', '/Product/Edit')" class="text-center">
                  <b-link
                    :to="{
                      name: '/Product/Edit',
                      params: { id: item.product_id }
                    }"
                    target="_blank"
                  >
                    <i class="fal fa-edit"></i>
                  </b-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-alert variant="danger" :show="tableReady" v-else>
          <h4 class="alert-heading d-flex justify-content-between">
            <div>
              {{ $t('company.price_list') }}
            </div>
            <b-button variant="none" size="sm" @click="getTableData()">
              <i class="fal fa-sync-alt text-danger" />
            </b-button>
          </h4>
          <div class="alert-body">
            <span>
              {{ $t('company_crud.3.price_list_not_found') }}
            </span>
          </div>
        </b-alert>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BPopover, BLink, BOverlay, BTable, BButton, BAlert } from 'bootstrap-vue'
import '@/assets/js/functions/datatables.settings'
import $ from 'jquery'
import { mapGetters } from 'vuex'
import ProductTooltip from '@/views/Order/Product/Tooltip.vue'
import { GET_PRODUCTS } from '@/store/services/product-service'
import moment from 'moment'
import { convertTL, priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'product-list',
  components: {
    BCard,
    BCardBody,
    BPopover,
    BLink,
    BOverlay,
    BTable,
    BButton,
    BAlert,

    ProductTooltip
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: false
    }
  },
  computed: {
    ...mapGetters(['getLocale', 'currency', 'currentUser'])
  },
  created() {
    this.$store
      .dispatch(GET_PRODUCTS)
      .then(response => {
        this.tableData = response
      })
      .catch(() => {
        this.tableData = []
      })
      .finally(() => {
        this.$nextTick(() => {
          this.createTable()
        })
      })
  },
  methods: {
    createTable() {
      this.tableReady = false
      let tempTradeName = this.tradeName()
      this.table = $('#priceListDataTable').DataTable({
        //columnDefs: [{ orderable: false, targets: [4] }],
        scrollX: true,
        scrollY: '1000px',
        scrollCollapse: true,
        buttons: [
          {
            extend: 'colvis',
            text: this.$t('dataTable.show_hide')
          },
          {
            extend: 'pdf',
            title: `${tempTradeName}
            ${this.$t('company.price_list')}`,
            filename: `${tempTradeName}_${this.$t('company.price_list')}_${moment().format('DD_MM_YYYY')}`,
            orientation: 'landscape',
            pageSize: 'LEGAL',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6]
            },
            customize: function(doc) {
              doc['footer'] = function(currentPage, pageCount) {
                return [
                  {
                    text: `${currentPage.toString()} / ${pageCount}`,
                    alignment: 'center'
                  }
                ]
              }
            }
          },
          {
            extend: 'excel',
            title: 'Bayi Fiyat Listesi',
            filename: 'Bayi Fiyat Listesi'
          }
        ],
        language: {
          url: this.getLocale === 'tr-TR' ? '//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json' : '//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json'
        }
      })
      this.tableReady = true
    },
    unitPriceTRY(product) {
      return convertTL(product.unit_price_tra ?? 0, product.currency_code ?? 'TRY', this.currency)
    },
    unitPriceShow(product) {
      return priceFormat(product.unit_price_tra ?? 0, this.getLocale, product.currency_code ?? 'TRY')
    },
    unitPriceTRYShow(product) {
      let price = this.unitPriceTRY(product)
      return priceFormat(price, this.getLocale, 'TRY')
    },
    reDraw() {
      Promise.all([this.table.destroy()]).then(this.createTable)
    },
    tradeName() {
      return this.currentUser.company?.company_trade_name
    }
  },
  watch: {
    getLocale() {
      this.reDraw()
    }
  }
}
</script>
